import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Sub_Navigation from "../components/common/navigation/sub_navigation"
import Integrations from "../components/sections/integrations"
import GetStarted from "../components/sections/getstarted"





const IntegrationsPage = () => (
    <Layout>
    <SEO title="Product" />
    <Sub_Navigation />
    <Integrations />
  </Layout>
)


export default IntegrationsPage