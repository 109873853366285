import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Google from "../../images/google_assistant.png"
import Alexa from "../../images/alexa.png"
import Siri from "../../images/siri.png"
import IFTTT from "../../images/ifttt.png"
import GoogleConnect from "../../images/google_connect.png"
import IFTTTApplet from "../../images/IFTTT_applet.png"
import GoogleTrigger from "../../images/trigger_google.png"
import GoogleTrigger2 from "../../images/google_simple_phrase.png"
import GoogleTrigger3 from "../../images/Trigger_Google_Phrase.png"
import GoogleAction from "../../images/create_action.png"
import Webhook from "../../images/webhook.png"
import Webhook2 from "../../images/webhook_request.png"


import { Section, Container } from "../global"
import GatsbyImage from "gatsby-image"

const Integrations = () => (
  <Section id="start">
    <StyledContainer>
      <SectionTitle>Integrate with voice control systems</SectionTitle>
      <FeaturesGrid>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#google">
        <img src={Google} alt="Google" style={{width: "50%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>Google Asisstant</FeatureTitle>
          <FeatureText>
            Integrate with Google Assistant and Google Home
          </FeatureText>
        </FeatureItem>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#alexa">
        <img src={Alexa} alt="Alexa" style={{width: "65%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>Alexa</FeatureTitle>
          <FeatureText>
          Integrate with Alexa
          </FeatureText>
        </FeatureItem>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#siri">
        <img src={Siri} alt="Siri" style={{width: "60%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>Siri.</FeatureTitle>
          <FeatureText>
            Integrate with Siri
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
       <Subtitle>Please feel free to contact <a href="mailto:support@arcoinnovation.com">support@arcoinnovation.com</a> in case you need assistance during integration.</Subtitle>
    </StyledContainer>

    <StyledContainer>
      <FeaturesGridHeader id="google">
        <FeatureItem>
        <SectionTitle>Integrate with Google Assistant</SectionTitle>
        </FeatureItem>
        </FeaturesGridHeader>
      <FeaturesGrid>
        <FeatureItem>
        <img src={IFTTT} alt="IFTTT" style={{width: "85%"}}/>  
          <FeatureTitle>Open an account at IFTTT</FeatureTitle>
          <FeatureText>
            a) Please visit<a href="https://ifttt.com/home">https://ifttt.com/home</a> 
            <br></br>
            b) If you haven’t already, sign in or sign up for an account
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={GoogleConnect} alt="" style={{width: "85%"}}/>  
          <FeatureTitle>Connect your account</FeatureTitle>
          <FeatureText>
            a) At the top right, click Search. Search for "Google Assistant" under services."
            <br></br>
            b) Click "Connect" and link to the google account linked to your Google Home device.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={IFTTTApplet} alt="" style={{width: "75%"}}/>  
          <FeatureTitle>Create an Applet in IFTTT </FeatureTitle>
          <FeatureText>
           a) From the create menu, select Applets
           <br></br>
           c) Create your own applet.
           <br></br>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={GoogleTrigger} alt="" style={{width: "100%"}}/>  
          <FeatureTitle>Choose your trigger </FeatureTitle>
          <FeatureText>
           a) Add a trigger ("If This")
           <br></br>
           c) Search Google Assistant service
           <br></br>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={GoogleTrigger2} alt="" style={{width: "85%"}}/>  
          <FeatureTitle>Specify the trigger type </FeatureTitle>
          <FeatureText>
           a) Select simple phrase trigger.
           <br></br>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={GoogleTrigger3} alt="" style={{width: "85%"}}/>  
          <FeatureTitle>Configure phrase</FeatureTitle>
          <FeatureText>
           a)Type the phrase that you want to say to trigger the action.
           <br></br>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={GoogleAction} alt="" style={{width: "85%"}}/>  
          <FeatureTitle>Add an Action</FeatureTitle>
          <FeatureText>
           a)Add action to perform after trigger
           <br></br>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Webhook} alt="" style={{width: "85%"}}/>  
          <FeatureTitle>Search Webhooks service</FeatureTitle>
          <FeatureText>
           a) Search webhooks service 
           <br></br>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Webhook2} alt="" style={{width: "85%"}}/>  
          <FeatureTitle>Configure Webhooks action</FeatureTitle>
          <FeatureText>
           a) Create your custom API URL by replacing DEVICE_ID with your Device ID and PASSWORD in the URL below. This information can be found on the lable at the back of your device.
           <br></br>
           <br></br>
          ARCO API URL: 
           <br></br>
           <br></br>
           <strong>https://fouzphc3pc.execute-api.us-east-1.amazonaws.com/default/Garage_opener?topic=[DEVICE_ID]-[PASSORD]/2&payload=1</strong> 
           <br></br>
           <br></br>
           b) Enter your custom API in the URL field of the Webhook configuration.

           <br></br>
          </FeatureText>
        </FeatureItem>


      </FeaturesGrid>


     
      <Subtitle>Please feel free to contact <a href="mailto:support@arcoinnovation.com">support@arcoinnovation.com</a> in case you need assistance during installation.</Subtitle>

    </StyledContainer>

  </Section>

)

export default Integrations

const StyledContainer = styled(Container)`
margin: 80px auto;`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
`

const FeaturesGridHeader = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  height: 50%;
`
