import React, { Component } from "react"
import { Link } from "gatsby"
import Logo from "../../../images/logo.png"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { Container } from "../../global"
import {
  Nav,
  Brand,
  StyledContainer,
  Mobile_Sub,
  ActionsContainer,
} from "./style"

const NAV_ITEMS = ["Features", "Product", "Pricing", ""]

export default class Navigation extends Component {
  state = {  
  }
  
  render() {
    
     return (
      <Nav>
        <StyledContainer>
          <Brand>
          <AnchorLink  offset='100' href="#start"><img src={Logo} alt="Logo" style={{width: "200px"}}/></AnchorLink>
          </Brand>
          <Mobile_Sub>
          <Link to="/">
          <button style={{ fontSize: "25px", color: "white", background: "none" }}>❮</button>
          </Link>
          </Mobile_Sub>      
         </StyledContainer>
      </Nav>
    )
  }
}
